.custome-style-pro-table {
  .ant-pro-table-search {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
    background-color: #f5f5f5;
    .ant-form-item {
      margin-bottom: 12px;
    }
  }
  .ant-pro-table-list-toolbar-container {
    padding-top: 0;
    margin-top: 0;
  }
  .ant-table-thead {
    th {
      min-width: 50px;
    }
  }
  .ant-card {
    background-color: #f5f5f5;
  }
  .ant-table {
    min-height: 429px;
    &-tbody > tr > td {
      border-bottom: 1px solid #d9d9d9;
    }
    &-thead > tr > th {
      border-bottom: 1px solid #d9d9d9;
    }
  }
  .ant-empty-normal {
    margin: 152px 0;
  }

  &.table-scroll {
    .ant-table {
      overflow: auto;
      max-height: 50vh;
      min-height: 60vh;
    }
  }
}
