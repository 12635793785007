html,
body,
button,
code,
dd,
details,
div,
dl,
dt,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
input,
li,
ol,
p,
td,
textarea,
th,
ul {
  margin: 0;
  padding: 0;
  outline: 0;
  -webkit-text-size-adjust: none;
  -webkit-tap-highlight-color: transparent;
}
* {
  box-sizing: border-box;
}
ul,
li,
ol {
  list-style: none;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* iphone6 7 8 */
html {
  font-size: 22px;
}

#root {
  height: 100%;
}
.ant-layout {
  height: 100%;
}
