.signin {
  background-image: url("../../../assets/img/background.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &-container {
    width: 452px;
    border-radius: 8px;
    backdrop-filter: blur(3px);
    box-shadow: 0 0 5px 0 rgba(106, 106, 106, 0.5);
    background-color: rgba(246, 245, 248, 0.25);
    padding: 45px 44px;
    &-title {
      color: #fff;
      font-size: 1.25rem;
      margin-bottom: 0.5rem;
    }
  }
  &-logo {
    margin-bottom: 2rem;
    width: 146px;
    img {
      width: 100%;
      object-fit: cover;
    }
  }
  &-forget-password {
    color: #46b3ff;
    display: none;
  }

  /* overwrite ant control default style*/
  .ant-form-item-has-error .ant-input:not(.ant-input-disabled),
  .ant-form-item-has-error .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled),
  .ant-form-item-has-error .ant-input:not(.ant-input-disabled):hover,
  .ant-form-item-has-error .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
  .ant-select {
    &-single:not(.ant-select-customize-input) .ant-select-selector {
      padding-left: 0;
    }
    &-arrow {
      color: #fff;
    }
  }
  input {
    &.ant-input {
      border-radius: 8px;
      background-color: rgba(0, 0, 0, 0.2);
      border: none;
      transition: none;
      &:focus {
        box-shadow: inset 0 0 0 1px #40a9ff !important;
      }
      &:not(.ant-input-disabled):hover {
        background-color: rgba(0, 0, 0, 0.2);
      }
    }
    padding: 18px;
    color: #fff !important;
  }
  .ant-input-affix-wrapper {
    padding: 9px;
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.2);
    border: none;
    & > input.ant-input {
      padding: 9px;
      background-color: transparent !important;
      border-radius: 0;
      &:focus {
        box-shadow: none !important;
      }
    }
    svg {
      color: #fff;
    }
    &:focus,
    &-focused {
      box-shadow: inset 0 0 0 1px #40a9ff;
    }
    &:not(.ant-input-affix-wrapper-disabled),
    &:not(.ant-input-affix-wrapper-disabled):hover {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  /* Removing input background colour for Chrome autocomplete */
  input:-webkit-autofill {
    &,
    &:hover,
    &:focus,
    &:active {
      transition-property: background-color, color;
      transition-timing-function: ease-in-out 0s;
      transition-delay: 5000s;
      -webkit-text-fill-color: #fff;
      box-shadow: 0 0 0px 1000px #435060 inset;
      // transition: all 5000s ease-in-out 0s;
    }
  }

  /* 客製化 btn style */
  .custom-btn {
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    border: 1px solid transparent;
    box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    touch-action: manipulation;
    padding: 8px 0;
    font-size: 16px;
    color: #fff;
    border-radius: 8px;
    &:hover,
    &:focus {
      filter: brightness(1.1);
    }
    &:active {
      filter: brightness(0.9);
    }
    &-login {
      width: 150px;
      background-color: #e58211;
      border-color: #e58211;
      text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
      box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
    }
  }

  .ant-form-item-label > label {
    color: #fff;
  }
  .ant-image-mask-info {
    display: none;
  }
}
